/* src/app/delivery-defense/dd-address-confidence-tool/recent-uploads/recent-uploads.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.recent-uploads-layout {
  width: 100%;
}
.recent-uploads-layout .recent-uploads-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  padding: 0 25px;
}
@media screen and (min-width: 75em) {
  .recent-uploads-layout .recent-uploads-content {
    width: 1150px;
    margin: 0 auto;
    position: relative;
    padding: 0 90px;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .recent-uploads-layout .recent-uploads-content {
    width: 980px;
    padding: 0 50px;
  }
}
@media screen and (max-width: 48rem) {
  .recent-uploads-layout .recent-uploads-content {
    padding: 0 1.5rem;
  }
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .recent-uploads-layout .recent-uploads-content .grid-actions-wrapper {
    padding: 0;
  }
}
@media screen and (min-width: 75em) {
  .recent-uploads-layout .recent-uploads-content .grid-actions-wrapper {
    padding: 0 25px;
  }
}
@media screen and (min-width: 100rem) {
  .recent-uploads-layout .recent-uploads-content .grid-actions-wrapper {
    flex-direction: row;
    align-items: center;
  }
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container {
  display: inline-flex;
  flex-direction: column;
}
@media screen and (min-width: 100rem) {
  .recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container {
    margin-left: auto;
  }
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container .date-picker-wrapper {
  display: inline-flex;
  flex-direction: column;
}
@media screen and (min-width: 100rem) {
  .recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container .date-picker-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
    align-items: center;
  }
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container .date-picker-wrapper mat-form-field {
  max-height: 55px;
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container .date-picker-actions {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1rem;
  -webkit-user-select: none;
  user-select: none;
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container .date-picker-actions .clear-link-button {
  text-decoration: underline;
  color: #0662BB;
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container .date-picker-actions .clear-link-button:hover {
  cursor: pointer;
  color: #009cbd;
}
.recent-uploads-layout .recent-uploads-content .grid-actions-wrapper .date-picker-container .date-picker-actions .done-link-button {
  border: 2px solid #004791;
  color: #004791;
  font-weight: 600;
  border-radius: 9999px;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container {
  display: block;
  width: 100%;
  margin-top: 10px;
}
@media screen and (min-width: 75em) {
  .recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container {
    padding: 0 25px;
  }
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 0 5px 0 rgba(0, 0, 0, 0.14);
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper ::ng-deep .dataTables_wrapper {
  padding: 0;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table thead tr th {
  background-color: #4a4a4c;
  min-width: 108px;
  padding: 1rem 0.5rem;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .scored-address-review-file {
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  text-decoration: underline;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .scored-address-review-file:hover {
  color: #009cbd;
  cursor: pointer;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .download-link {
  text-align: center;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .download-link .download-icon {
  color: var(--ups-brand-colors-ups-blue, #0662BB);
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .download-link .download-icon:hover {
  color: #009cbd;
  cursor: pointer;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .upload-status-wrapper {
  display: inline-flex;
  flex-direction: row;
  gap: 0.5rem;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .upload-status-wrapper .chip {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .upload-status-wrapper .chip.green-status {
  background-color: #D4EEDD;
  color: #10602C;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .upload-status-wrapper .chip.blue-status {
  background-color: #e6eff8;
  color: var(--ups-brand-colors-ups-blue, #0662BB);
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .upload-status-wrapper .chip.yellow-status {
  background-color: #FEF6CC;
  color: var(--ups-yellow, #FBD300);
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .upload-status-wrapper .refresh-button {
  color: black;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper table.ups-table tbody tr .upload-status-wrapper .refresh-button:hover {
  cursor: pointer;
  color: var(--ups-green, #00857d);
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .filter-list-icon {
  float: right;
  margin-top: -2px;
  cursor: pointer;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .header-wrapper .ups-logo {
  width: 16.805px;
  height: 20px;
  margin-right: 5px;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .help-container {
  position: relative;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .help-container .margin-top-2px {
  margin-top: 2px;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .help-container .help-icon {
  float: right;
  position: absolute;
  top: -1rem;
  right: -1rem;
  cursor: pointer;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .help-container .help-popup-message-bubble {
  display: none;
  position: absolute;
  background: rgba(151, 151, 151, 0.9);
  width: 120px;
  height: 140px;
  box-shadow:
    0 15px 32px -3px rgba(0, 0, 0, 0.08),
    0 4px 12px -2px rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06);
  color: #121212;
  border-radius: 4px;
  text-align: center;
  z-index: 9998;
  font-size: 14px;
  white-space: pre-wrap;
  margin-top: 5px;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .help-container .help-popup-message-bubble p {
  padding: 15px 0 15px 0;
  margin: 0;
  color: white;
  word-wrap: break-word;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .grid-wrapper .ups-table-rows .help-container .help-icon:hover + .help-popup-message-bubble {
  display: block;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .pagination-wrapper {
  margin: 10px 0 50px 0;
  border-radius: 0 0 2.984px 2.984px;
  border-top: 0.746px solid var(--UPSC-Gray-2, #757583);
  background: #FFF;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), inset 0px 0 5px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  justify-content: center;
}
@media screen and (min-width: 75em) {
  .recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .pagination-wrapper {
    padding: 0;
  }
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .pagination-wrapper ::ng-deep pagination-controls .ngx-pagination {
  justify-content: center;
}
@media screen and (min-width: 75em) {
  .recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container .pagination-wrapper ::ng-deep pagination-controls .ngx-pagination {
    justify-content: flex-end;
  }
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container ::ng-deep upsc-custom-paginator .inner .disabled-link {
  color: #AFB1BF;
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container ::ng-deep upsc-custom-paginator #outer {
  height: auto;
  padding-top: 0;
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container ::ng-deep upsc-custom-paginator #outer {
    height: 80px;
  }
}
.recent-uploads-layout .recent-uploads-content .recent-uploads-grid-container ::ng-deep upsc-custom-paginator .mat-mdc-text-field-wrapper {
  width: 110%;
  margin-top: 20px;
}
/*# sourceMappingURL=recent-uploads.component.css.map */
