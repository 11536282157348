/* src/app/delivery-defense/delivery-defense-sign-up/confirm-popup/confirm-popup.component.scss */
.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 4px;
  background: var(--ups-brand-colors-ups-teal-light, #F8FBFB);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 3.63rem 3.5rem;
  position: relative;
  z-index: 0;
  overflow: auto;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content {
    height: 200%;
  }
}
.dialog-content h1,
.dialog-content h2,
.dialog-content h3,
.dialog-content h4 {
  margin: 0;
}
.dialog-content .content {
  height: 100%;
}
.dialog-content .content .title {
  display: flex;
  font-size: 2.0625rem;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  line-height: 3.875rem;
}
.dialog-content .content .title .close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  z-index: 99;
}
.dialog-content .content .title h1 {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.dialog-content .content .title .separtor {
  width: 100px;
  height: 4px;
  background: #004791;
  margin-top: 0.5em;
}
.dialog-content .content .complementaryPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  margin-top: 2em;
}
.dialog-content .content .complementaryPanel .cmplmry-section {
  display: flex;
  width: 24.1875rem;
  height: 6.4375rem;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  border: 3px solid #004791;
  background: var(--ups-brand-colors-ups-white, #FFF);
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section {
    width: 20em;
  }
}
.dialog-content .content .complementaryPanel .cmplmry-section .option-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  width: 100%;
  font-size: 1rem;
}
.dialog-content .content .complementaryPanel .cmplmry-section .option-card .radio-button img {
  filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}
.dialog-content .content .complementaryPanel .cmplmry-section .option-card .card-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  row-gap: 1rem;
}
.dialog-content .content .complementaryPanel .cmplmry-section .option-card .card-info .card-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
}
.dialog-content .content .complementaryPanel .cmplmry-section .option-card .card-info .card-details .renewal {
  color: #5F5753;
  font-size: 0.8em;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section .section {
    flex-wrap: nowrap;
    width: 35%;
  }
}
.dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section {
  margin-right: 9px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section {
    margin: 0px;
    width: 35%;
  }
}
.dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section .Radio {
  width: 24px;
  height: 24px;
  margin-top: 1.5em;
}
.dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section .renews {
  display: flex;
  width: 219px;
  height: 69px;
  margin-left: 0.9em;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  color: var(--ups-brand-colors-ups-grey-1, #5F5753);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section .renews {
    font-size: 15px;
    margin-left: 5em;
  }
}
.dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h1 {
  display: flex;
  width: 306px;
  height: 38px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-family: "Roboto Flex", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h1 {
    font-size: 15px;
  }
}
.dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h2 {
  display: flex;
  width: 225px;
  height: 32px;
  margin-top: -4px;
  flex-direction: column;
  justify-content: center;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h2 {
    font-size: 15px;
  }
}
.dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h3 {
  display: flex;
  width: 167px;
  height: 33px;
  flex-direction: column;
  justify-content: center;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h3 {
    font-size: 18px;
  }
}
.dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h4 {
  display: flex;
  width: 354px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .cmplmry-section .section .sub-section h4 {
    font-size: 15px;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section {
  display: flex;
  margin-top: 3em;
  margin-bottom: 3em;
  width: 24.1875rem;
  height: 6.4375rem;
  padding: 16px 24px 16px 16px;
  align-items: center;
  gap: 14px;
  border-radius: 7px;
  border: 3px solid #004791;
  background: var(--ups-brand-colors-ups-white, #FFF);
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section {
    width: 20em;
  }
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section .section {
    flex-wrap: nowrap;
    width: 35%;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section {
  margin-right: 9px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section {
    margin: 0px;
    width: 35%;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section .Radio {
  width: 24px;
  height: 24px;
  margin-top: 1.3em;
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section .renews {
  margin-left: 1.2em;
  display: flex;
  width: 221px;
  height: 69px;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--ups-brand-colors-ups-grey-1, #5F5753);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section .renews {
    font-size: 15px;
    margin-left: 5em;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h1 {
  display: flex;
  width: 282px;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h1 {
    font-size: 18px;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h2 {
  display: flex;
  width: 167px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h2 {
    font-size: 18px;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h3 {
  display: flex;
  width: 167px;
  height: 33px;
  flex-direction: column;
  justify-content: center;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h3 {
    font-size: 18px;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h4 {
  display: flex;
  width: 354px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .lvlcmplmry-section .section .sub-section h4 {
    font-size: 15px;
  }
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .option-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  width: 100%;
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .option-card .card-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  row-gap: 1rem;
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .option-card .card-info .card-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
}
.dialog-content .content .complementaryPanel .lvlcmplmry-section .option-card .card-info .card-details .renews {
  color: #5F5753;
}
.dialog-content .content .complementaryPanel .agreement {
  display: flex;
  width: 720px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .agreement {
    width: 20em;
    padding: 10px;
  }
}
.dialog-content .content .complementaryPanel .agreement h3 {
  width: 100%;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .agreement h3 {
    font-size: 18px;
  }
}
.dialog-content .content .complementaryPanel .agreement .agreementLabel .label {
  width: 100%;
  margin-bottom: 2em;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.dialog-content .content .complementaryPanel .agreement .agreementLabel .label a {
  text-align: center;
  width: 77px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .agreement .agreementLabel .label {
    font-size: 14px;
  }
}
.dialog-content .content .complementaryPanel .agreement p {
  margin-top: 0.5em;
}
.dialog-content .content .complementaryPanel .agreement p ::ng-deep .mat-mdc-checkbox .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;
  margin-right: 1em;
}
.dialog-content .content .complementaryPanel .agreement p .panel h1 {
  display: flow;
  color: var(--ups-brand-colors-ups-grey-1, #5F5753);
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .agreement p .panel h1 {
    font-size: 15px;
  }
}
.dialog-content .content .complementaryPanel .agreement p .panel h1 a {
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .agreement p .panel h1 a {
    font-size: 15px;
  }
}
.dialog-content .content .complementaryPanel .agreement .agreementPanel {
  width: 100%;
  color: var(--ups-brand-colors-ups-grey-1, #5F5753);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-top: 20px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .agreement .agreementPanel {
    font-size: 14px;
  }
}
.dialog-content .content .complementaryPanel .confirmedLabel {
  display: flex;
  width: 745px;
  height: 55px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.dialog-content .content .complementaryPanel .mat-mdc-raised-button {
  width: 387px;
  height: 56px;
  font-size: 20px;
  cursor: pointer;
  min-width: 100px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .content .complementaryPanel .mat-mdc-raised-button {
    margin-bottom: 0em;
    width: 100%;
  }
}
.dialog-content .content .complementaryPanel button {
}
.dialog-content .content .complementaryPanel button .mat-button-wrapper {
  color: var(--ups-brand-colors-ups-white, #FFF);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.dialog-content .subscribedPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  margin-top: 6em;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .subscribedPanel {
    width: 100%;
  }
}
.dialog-content .subscribedPanel .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  line-height: 3.875rem;
}
.dialog-content .subscribedPanel .title .close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  z-index: 99;
}
.dialog-content .subscribedPanel .title h1 {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .subscribedPanel .title h1 {
    font-size: 35px;
  }
}
.dialog-content .subscribedPanel .title .separtor {
  width: 100px;
  height: 4px;
  background: #004791;
  margin-top: 0.5em;
}
.dialog-content .subscribedPanel .close-button {
  display: flex;
  flex-direction: column;
  width: 16px;
  height: 15.934px;
  flex-shrink: 0;
  color: #000;
  margin-top: 19px;
  margin-right: 25px;
  cursor: pointer;
}
.dialog-content .subscribedPanel .close-button .material-icons {
  font-size: 1.525rem !important;
}
.dialog-content .subscribedPanel .confirmedLabel {
  display: flex;
  width: 75%;
  height: 55px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .subscribedPanel .confirmedLabel {
    width: 100%;
    font-size: 21px;
  }
}
.dialog-content .subscribedPanel .startNowBtn {
  width: 41%;
  height: 56px;
  padding: 10px 24px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
@media only screen and (max-width: 32.6em) {
  .dialog-content .subscribedPanel .startNowBtn {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
  }
}
button.tab-trap {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
::ng-deep .mat-mdc-checkbox .mdc-form-field {
  align-items: flex-start;
}
/*# sourceMappingURL=confirm-popup.component.css.map */
