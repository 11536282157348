/* src/app/delivery-defense/dd-manage-subscription/table-popup/table-popup.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.table-dialog {
  display: flex;
  flex-direction: column;
  width: 855px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--ups-brand-colors-ups-teal-light, #F8FBFB) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
}
.table-dialog .header {
  background: var(--ups-brand-colors-ups-teal-light, #F8FBFB) !important;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.table-dialog .header-dropdown {
  width: 100%;
  padding: 0 1.5rem;
  flex-shrink: 0;
  background: #FFF;
}
@media only screen and (max-width: 23.438rem) {
  .table-dialog .header-dropdown {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .table-dialog .header-dropdown {
    max-width: 122%;
    margin-left: 0em;
  }
}
.table-dialog .header-dropdown .ddSection {
  width: 800px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 23.438rem) {
  .table-dialog .header-dropdown .ddSection {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .table-dialog .header-dropdown .ddSection {
    max-width: 100%;
    margin-left: 0em;
  }
}
.table-dialog .header-dropdown .ddSection .expand_more {
  width: 12px;
  height: 7px;
  flex-shrink: 0;
  cursor: pointer;
}
.table-dialog .header-dropdown .ddSection h1 {
  width: 259px;
  height: 21px;
  float: left;
  flex-shrink: 0;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-left: 2em;
}
.table-dialog .header-dropdown .tablePanel {
  padding-top: 2rem;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-style: normal;
}
@media only screen and (max-width: 32.6em) {
  .table-dialog .header-dropdown .tablePanel {
    overflow: scroll;
  }
}
.table-dialog .header-dropdown .tablePanel table.my-table {
  border: none;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
.table-dialog .header-dropdown .tablePanel thead.my-table.headers tr th {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 124px;
  height: 56px;
}
.table-dialog .header-dropdown .tablePanel thead.my-table.headers tr th .sideHeader {
  text-wrap: nowrap;
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.background1 {
  background: var(--ups-brand-colors-ups-teal-light, #F8FBFB);
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.background2 {
  background: var(--semantic-colors-ups-feature-teal-light, #E7F2F2);
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.background3 {
  background: var(--ups-brand-colors-ups-teal-medium, #C2E3E2);
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.background4 {
  background: rgba(0, 154, 144, 0.38);
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader {
  display: flex;
  width: 180px;
  height: 70px;
  padding: 24px 0px 24px 10px;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader {
    height: 84px;
  }
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader sup {
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel {
  padding: 0px 0px 0px 0px !important;
  display: flex;
  flex-direction: column;
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS {
  font-size: 14px;
  display: flex;
  width: 180px;
  height: 70px;
  padding: 0px 0px 0px 11px;
  align-items: flex-end;
  text-align: left;
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS {
    height: 84px;
  }
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS .rated {
  top: -7px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
  position: relative;
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessPoint {
  display: flex;
  width: 180px;
  height: 70px;
  padding: 0px 0px 0px 11px;
  align-items: flex-start;
  text-align: left;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel {
    height: 84px;
  }
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .rated {
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.label {
  flex-direction: column;
  align-items: flex-start;
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.dot {
  width: 124px;
  height: 56px;
  padding: 24px 0px;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.table-dialog .header-dropdown .tablePanel tbody.my-table.body tr td.last {
  border-right: none;
}
.table-dialog .header-dropdown .tablePanel h2 {
  color: #000;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 7px;
}
.table-dialog .header-dropdown .tablePanel h2 a {
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
/*# sourceMappingURL=table-popup.component.css.map */
