/* src/app/delivery-defense/dd-score-history/dd-score-history-table/dd-score-history-table.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.score-history-layout {
  width: 100%;
}
.score-history-layout .score-history-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content {
    width: 70%;
    margin: 0 auto;
    position: relative;
    padding: 0px 90px 20px 90px;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .score-history-layout .score-history-content {
    width: 70%;
    padding: 0px 50px 0px 50px;
  }
}
.score-history-layout .score-history-content .dd-score-history-table {
  width: 100%;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-actions-wrapper {
  padding: 0 25px 15px 25px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-description {
  width: 100%;
  padding-bottom: 25px;
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}
.score-history-layout .score-history-content .dd-score-history-table .table-description .trademark {
  font-size: 10px;
  margin-top: 5px;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions {
  border-radius: 4px;
  border: 1px solid var(--UPSC-Gray-1, #4A4A4C);
  background: var(--ups-brand-colors-ups-grey-5, #EFEFF2);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  padding: 15px;
  flex-wrap: wrap;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .left-action {
  background: var(--ups-brand-colors-ups-white, #FFF);
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-left: auto;
}
@keyframes fadeinmobile {
  from {
    right: -33vw;
  }
  to {
    right: 0;
  }
}
@keyframes fadein {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter {
  position: relative;
  pointer-events: visible;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-wrapper svg {
  vertical-align: middle;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-wrapper span {
  vertical-align: middle;
  color: var(--UPSC-Gray-1, #4A4A4C);
  text-align: center;
  font-family: "Roboto Flex", sans-serif;
  font-size: 13px;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-panel {
  row-gap: 0.5rem;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: white;
  border-radius: 4px;
  padding: 1rem 3rem;
  animation: fadein 1s;
}
@media screen and (min-width: 100em) {
  .score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-panel {
    width: 33vw;
    animation: fadeinmobile 1s;
    row-gap: 1.2rem;
  }
}
@media screen and (min-width: 65em) and (max-width: 100em) {
  .score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-panel {
    width: 40vw;
    animation: fadeinmobile 1s;
    row-gap: 1.2rem;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-panel .title {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  position: relative;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-panel .title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  width: 4.5rem;
  height: 0.25rem;
  background-color: #00857D;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-panel .p {
  margin-bottom: 8px;
  color: var(--ups-brand-colors-ups-grey-1, #5F5753);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 3rem;
  position: relative;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .right-actions .advanced-filter .advanced-filter-panel .divider {
  content: "";
  position: relative;
  width: 100%;
  height: 0.7px;
  background: #BFB8AF;
  margin-top: 25px;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .advanced-filter-btn {
  background-color: transparent;
  font-size: 16px;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .advanced-filter-btn.active {
  background-color: #dfdbd7;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .category-form {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .category-form {
    max-width: 200px;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .category-form ::ng-deep .mat-mdc-form-field {
  width: 100%;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-table-form {
  width: 100%;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-table-form .search-icon {
  margin-right: -8px;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-table-form {
    max-width: 298.355px;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-table-form .mat-mdc-form-field {
  bottom: 5px;
  width: 100%;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-table-form .mat-mdc-form-field .search-table-form-items {
  display: inline-flex;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-table-form .mat-mdc-form-field .search-table-form-items input {
  margin-left: 5px;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .date-form {
  height: 55px;
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .date-form {
    max-width: 220px;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .date-form .mat-mdc-form-field {
  margin-top: 9px;
  margin-left: 10px;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-button {
  padding: 0 20px;
  border-radius: 3px;
  border: none;
  height: 38px;
}
.score-history-layout .score-history-content .dd-score-history-table .score-history-table-actions .search-button .button-label {
  font-family: Roboto, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.score-history-layout .score-history-content .dd-score-history-table .adv-filter-chips {
  display: flex;
  padding: 5px 0.5rem;
  border-radius: 7px;
  background: #C2E3E2;
  margin: 2px 0 13px 1.5rem;
  max-width: 192px;
}
.score-history-layout .score-history-content .dd-score-history-table .adv-filter-chips .bar-chart {
  width: 21px;
  height: 21px;
}
.score-history-layout .score-history-content .dd-score-history-table .adv-filter-chips .x-close-btn {
  width: 14px;
  height: 14px;
  margin-top: 2.5px;
  margin-left: 7px;
  flex-grow: 1;
  cursor: pointer;
}
.score-history-layout .score-history-content .dd-score-history-table .adv-filter-chips span {
  color: #075A5A;
  font-family: Roboto, serif;
  font-size: 12.25px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
  margin-left: 7px;
  margin-top: 1.3px;
}
.score-history-layout .score-history-content .dd-score-history-table .search-input-error-message {
  display: flex;
  color: black;
  font-size: 16px;
  border-left: 2.238px solid black;
  max-width: 50%;
  padding: 12px 9px;
  margin: 2px 0 13px 1.5rem;
  line-height: 18px;
  background: #FCEAE6;
  border-left-color: #DF2901;
}
.score-history-layout .score-history-content .dd-score-history-table .search-input-error-message svg {
  margin-right: 5px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container {
  display: block;
  padding: 0 1.5rem;
  width: 100%;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 0 5px 0 rgba(0, 0, 0, 0.14);
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper ::ng-deep .dataTables_wrapper {
  padding: 0;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper a:link {
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper a {
  text-decoration: underline;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper a:hover,
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper a:visited {
  cursor: pointer;
  color: #0662BB;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper table.ups-table thead tr th {
  background-color: #4a4a4c;
  min-width: 108px;
  padding: 1rem 0.5rem;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper table.ups-table thead tr th:nth-child(1) {
  min-width: 200px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper table.ups-table thead tr th:nth-child(2) {
  min-width: 180px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper table.ups-table thead tr th:nth-child(7) {
  min-width: 127px;
  padding-right: 1.7rem;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .score-backgrounds {
  width: 53px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 0 auto;
  font-weight: 500;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .green-background {
  background: var(--Semantic-Colors-UPS-Success-Green-Light, #DBF0DB);
  color: #16552C;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .yellow-background {
  background: var(--Semantic-Colors-UPS-Warning-Yellow-Light, #FEF6CC);
  color: #615100;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .red-background {
  background: var(--Semantic-Colors-UPS-Error-Red-Light, #FCEAE6);
  color: #991C00;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .gray-background {
  background: #E5E4E2;
  color: #71797E;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper table.ups-table tbody tr .pick-up-locations {
  text-align: center;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .filter-list-icon {
  float: right;
  margin-top: -2px;
  cursor: pointer;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .header-wrapper .ups-logo {
  width: 16.805px;
  height: 20px;
  margin-right: 5px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .help-container {
  position: relative;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .help-container .margin-top-2px {
  margin-top: 2px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .help-container .help-icon {
  float: right;
  position: absolute;
  top: -1rem;
  right: -1rem;
  cursor: pointer;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .help-container .help-popup-message-bubble {
  display: none;
  position: absolute;
  background: rgba(151, 151, 151, 0.9);
  width: 120px;
  height: 140px;
  box-shadow:
    0 15px 32px -3px rgba(0, 0, 0, 0.08),
    0 4px 12px -2px rgba(0, 0, 0, 0.08),
    0 2px 6px 0 rgba(0, 0, 0, 0.06);
  color: #121212;
  border-radius: 4px;
  text-align: center;
  z-index: 9998;
  font-size: 14px;
  white-space: pre-wrap;
  margin-top: 5px;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .help-container .help-popup-message-bubble p {
  padding: 15px 0 15px 0;
  margin: 0;
  color: white;
  word-wrap: break-word;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .table-wrapper .ups-table-rows .help-container .help-icon:hover + .help-popup-message-bubble {
  display: block;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .pagination-wrapper {
  margin: 10px 0 50px 0;
  border-radius: 0 0 2.984px 2.984px;
  border-top: 0.746px solid var(--UPSC-Gray-2, #757583);
  background: #FFF;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), inset 0px 0 5px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  justify-content: center;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content .dd-score-history-table .table-container .pagination-wrapper {
    padding: 0;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .table-container .pagination-wrapper ::ng-deep pagination-controls .ngx-pagination {
  justify-content: center;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content .dd-score-history-table .table-container .pagination-wrapper ::ng-deep pagination-controls .ngx-pagination {
    justify-content: flex-end;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .table-container ::ng-deep upsc-custom-paginator .inner .disabled-link {
  color: #AFB1BF;
}
.score-history-layout .score-history-content .dd-score-history-table .table-container ::ng-deep upsc-custom-paginator #outer {
  height: auto;
  padding-top: 0;
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .score-history-layout .score-history-content .dd-score-history-table .table-container ::ng-deep upsc-custom-paginator #outer {
    height: 80px;
  }
}
.score-history-layout .score-history-content .dd-score-history-table .table-container ::ng-deep upsc-custom-paginator .mat-mdc-text-field-wrapper {
  width: 110%;
  margin-top: 20px;
}
.score-history-layout .recent-upload-popup {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  padding: 0;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .recent-upload-popup {
    margin: 0 auto;
    position: relative;
  }
}
/*# sourceMappingURL=dd-score-history-table.component.css.map */
