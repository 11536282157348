/* src/app/shared/components/terms-conditions-static/dialog/terms-conditions-static-dialog.component.scss */
.dialog-header {
  background-color: #dfdbd7;
  position: sticky;
  top: 0;
  z-index: 9;
}
.dialog-content .title {
  font-size: 1.25rem;
  padding: 0.75em 3em;
  background-color: #dfdbd7;
  position: sticky;
  top: 0;
}
.dialog-actions {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  justify-content: center;
  padding: 1rem;
}
.dialog-actions .mdc-button {
  min-width: 8rem;
}
:host(.terms-conditions-static-dialog) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
}
/*# sourceMappingURL=terms-conditions-static-dialog.component.css.map */
