/* src/app/delivery-defense/delivery-defense-sign-up/delivery-defense-sign-up.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.sign-up-layout,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.sign-up-layout {
  min-width: 23.5375em;
  max-width: 71.875rem;
  width: 100%;
  font-size: 1rem;
}
@media screen and (min-width: 48rem) {
  .sign-up-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: 100%;
  }
}
.sign-up-layout .DD-sign-up {
  font-size: 1rem;
  margin-top: 1em;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 0px 16.564px 0px;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up {
    max-width: 100%;
    margin-left: 3.5em;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up {
    max-width: 100%;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper {
    max-width: 100%;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper {
    max-width: 100%;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel {
  width: 100%;
  background: var(--capital-gradent-top-component, linear-gradient(351deg, #DCE8E7 24%, #F3F3F3 74.74%));
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel {
    margin-left: 0em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel {
  margin-top: 1em;
  height: 167px;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel {
    height: 357px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section {
  position: relative;
  margin-left: 7em;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section {
    max-width: 100%;
    margin-left: 0em !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 4.5em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image1 {
  width: 100%;
  height: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image1 .hero-large {
  position: relative;
  width: 281.484px;
  height: 281.513px;
  margin-top: 8em;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image1 .hero-large {
    margin-top: 0px;
    margin-left: -1em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 {
  width: 350px;
  height: 200px;
  margin-left: 6.3em;
  position: relative;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 .hero-large {
  margin-top: 2em;
  margin-left: -1em;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 .play_circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  margin-top: 2em;
  margin-left: -2em;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 .play_circle {
    margin-top: 0em;
    margin-left: 0em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 h1 {
  display: flex;
  color: var(--ups-brand-colors-ups-teal, #0A8080);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 15.73px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.04px;
  text-decoration-line: underline;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: -3em;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .panel .section .image2 h1 {
    margin-left: 0em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .hero-small {
  width: 100%;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .banner-Panel .hero-small {
    margin-top: 6px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content {
  background: #FFF;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  flex-shrink: 0;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content {
    max-width: 100%;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  margin-top: 31px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -1.5em;
    gap: 0px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .header {
  display: flex;
  margin-top: 35px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .header {
    font-size: 28px;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .header {
    font-size: 30px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -4em;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox {
    flex-direction: column;
    margin-left: 5em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel1 {
  float: left;
  display: grid;
  gap: 21px;
  align-content: space-between;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel1 {
    gap: 0px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel1 .section1 {
  margin-bottom: 0.5em;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  width: 425px;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel1 .section1 {
    font-size: 14px;
    width: 332px;
    text-wrap: wrap;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel1 .section1 .checkmark_1 {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-top: -7px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel1 .section1 .trademark {
  font-size: 16px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 {
  display: grid;
  gap: 21px;
  align-content: space-between;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 {
    gap: 0px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 .section2 {
  margin-bottom: 7px;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  width: 425px;
  align-items: flex-start;
  gap: 14px;
  flex-shrink: 0;
  line-height: 24px;
  text-wrap: nowrap;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 .section2 {
    font-size: 14px;
    width: 324px;
    text-wrap: wrap;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 .section2 .checkmark_1 {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-top: -7px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 .section2 .trademark {
  font-size: 16px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 .section2 .trademark .ratedSymbal {
  margin-right: 6px;
  font-size: 11px;
  position: relative;
  top: -5px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .feature_panel .checkbox .sectionPanel2 .section2 .trademark .ratedtext {
  margin-left: -10px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel {
    max-width: 100% !important;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel h1 {
  height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel h1 {
    font-size: 30px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .label {
  display: flex;
  width: 442px;
  height: 11px;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .label a {
  width: 90px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .labelDescription {
  display: flex;
  width: 442px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: "Roboto Flex", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  padding: 1em 0;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .labelDescription {
    width: 100%;
    margin-top: 2em;
    margin-left: 1em;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .labelDescription {
    max-width: 93% !important;
    margin-left: 1em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .labelDescription .trademark sup {
  margin-right: 13px;
  margin-top: -14px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .labelDescription .trademark .ratedtext {
  margin-left: -10px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .labelDescription h1 {
  display: flex;
  width: 442px;
  height: 54px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: "Roboto Flex", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .label2 {
  width: 442px;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
  margin-top: 9px;
  margin-left: -4px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up {
    width: 95%;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up {
    max-width: 95% !important;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up .sign-up-panel {
  margin-top: 29px;
  display: flex;
  width: 484px;
  height: 56px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up .sign-up-panel {
    width: 100%;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up .sign-up-panel {
    max-width: 97% !important;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up .sign-up-panel .sign-up-button {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  padding: 1.5em;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up .sign-up-panel h1 {
  margin-top: 9px;
  color: var(--ups-brand-colors-ups-white, #FFF);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up .sign-up-panel h1 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up .sign-up-panel h1 {
    font-size: 17px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up h1 {
  margin-top: 1em;
  display: flex;
  width: 484px;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up h1 {
    width: 100%;
    font-size: 14px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up h2 {
  display: flex;
  width: 484px;
  margin-top: -0.6em;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .subscription-Panel .sign-up h2 {
    width: 100%;
    font-size: 14px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown {
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown {
    max-width: 100%;
    align-items: flex-start;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown {
  width: 800px;
  flex-shrink: 0;
  border-top: 1px solid var(--ups-brand-colors-ups-grey-2, #8C857E);
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-2, #8C857E);
  background: #FFF;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown {
    max-width: 99% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown {
    max-width: 100%;
    margin-left: 0em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .ddSection {
  width: 800px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .ddSection {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .ddSection {
    max-width: 100%;
    margin-left: 0em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .ddSection .expand_more {
  width: 12px;
  height: 7px;
  flex-shrink: 0;
  cursor: pointer;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .ddSection h1 {
  width: 259px;
  height: 21px;
  float: left;
  flex-shrink: 0;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-left: 2em;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel {
  margin-top: 0.5em;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-style: normal;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel {
    overflow: scroll;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel table.my-table {
  border: none;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel thead.my-table.headers tr th {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 124px;
  height: 56px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel thead.my-table.headers tr th .sideHeader {
  text-wrap: nowrap;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background1 {
  background: var(--ups-brand-colors-ups-teal-light, #F8FBFB);
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background2 {
  background: var(--semantic-colors-ups-feature-teal-light, #E7F2F2);
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background3 {
  background: var(--ups-brand-colors-ups-teal-medium, #C2E3E2);
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background4 {
  background: rgba(0, 154, 144, 0.38);
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader {
  display: flex;
  width: 180px;
  height: 70px;
  padding: 24px 0px 24px 10px;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader {
    height: 84px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader .rated {
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel {
  padding: 0px 0px 0px 0px !important;
  display: flex;
  flex-direction: column;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS {
  font-size: 14px;
  display: flex;
  width: 180px;
  height: 70px;
  padding: 0px 0px 0px 11px;
  align-items: flex-end;
  text-align: left;
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS {
    height: 84px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS .rated {
  top: -7px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
  position: relative;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessPoint {
  display: flex;
  width: 180px;
  height: 70px;
  padding: 0px 0px 0px 11px;
  align-items: flex-start;
  text-align: left;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel {
    height: 84px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .rated {
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.label {
  flex-direction: column;
  align-items: flex-start;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.dot {
  width: 124px;
  height: 56px;
  padding: 24px 0px;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.last {
  border-right: none;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel h2 {
  color: #000;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 7px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel h2 a {
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel h2 {
    margin-left: 11px;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .dropdown .header-dropdown .tablePanel h2 {
    margin-left: 11px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo {
    width: 100%;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo h1 {
  cursor: pointer;
  color: #397AB7;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 44.8px;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo h1 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo h1 {
    font-size: 23px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel {
    overflow: scroll;
    width: 99%;
    margin-left: 0.5em;
    padding: 10px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .sliderScale {
  width: 799.342px;
  height: 16.447px;
  flex-shrink: 0;
  border-radius: 6.579px;
  background:
    linear-gradient(
      90deg,
      #F66E6E 0%,
      #F6AC68 25%,
      #F1D367 49.48%,
      #E1F46D 72.4%,
      #97F26C 100%);
  box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable {
  width: 799.342px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels {
  width: 799.342px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels .lowConfidence,
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels .highconfidence {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels .lowConfidence,
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels .highconfidence {
    font-size: 18px !important;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels .lowConfidence {
  float: left;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels .highconfidence {
  float: right;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels h2 {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels h2 {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .labels h2 {
    font-size: 14px !important;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .my-table {
  margin-top: 2em;
  font-style: normal;
  font-feature-settings: "clig" off, "liga" off;
  width: 799.342px;
  font-family: "Roboto Flex", sans-serif;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .my-table thead.my-table.headers tr th {
  width: 175.658px;
  height: 36.842px;
  padding: 15.789px 0px;
  gap: 38.158px;
  flex-shrink: 0;
  color: var(--ups-brand-colors-ups-white, #FFF);
  background: rgb(56, 56, 56);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-right: 0.658px solid var(--ups-brand-colors-ups-white, #FFF);
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .my-table tbody.my-table.body tr td {
  width: 175.658px;
  padding: 15.789px 0px;
  color: var(--ups-brand-colors-ups-black, #121212);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 10.526px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.421px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .my-table tbody.my-table.body tr td .size {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 13.158px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.421px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .infoTable .my-table tbody.my-table.body tr {
  border-bottom: inset;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .labels {
  width: 799.342px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .labels .lowConfidence {
  float: left;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .labels .highconfidence {
  float: right;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .scoringInfo .scoringInfoPanel .labels h2 {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup {
    max-width: 94% !important;
    margin-left: 0.8em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup {
    width: 97%;
    margin-left: 0.5em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup h1 {
  display: flex;
  width: 1124px;
  height: 57px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup h1 {
    max-width: 100% !important;
    font-size: 27px;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup h1 {
    max-width: 100% !important;
    font-size: 27px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup .expansionPanel {
  margin-top: 12px;
  width: 800px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup .expansionPanel {
    width: 100%;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup .expansionPanel {
    width: 100%;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup .expansionPanel .panelHeader {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup .expansionPanel .panelBody {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .faqSignup .expansionPanel .panelBody a {
  cursor: pointer;
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection {
    max-width: 94% !important;
    margin-left: 0.8em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection {
    width: 97%;
    margin-left: 0.5em;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  min-width: 17.3em;
  margin-bottom: 1em;
}
@media screen and (min-width: 64rem) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links {
    display: flex;
    width: 800px;
  }
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links {
    min-width: 0em;
    width: 98%;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links {
    min-width: 0em;
    width: 98%;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links .divider {
  border-left: solid thin #0662BB;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links .link {
  cursor: pointer;
  color: #0662BB;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0em 2em;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links .link {
    font-size: 11px;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links .link {
    font-size: 11px;
  }
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links .disabled {
  color: #AFB1BF;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .linksSection .links .cancelSubscription {
  border-left: none;
}
.sign-up-layout .DD-sign-up .sign-up-wrapper .content .disclaimer {
  display: flex;
  margin-top: -26px;
  flex-direction: row;
  justify-content: center;
  color: #5F5753;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16.5px;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .disclaimer {
    max-width: 98% !important;
    margin-left: 1.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .DD-sign-up .sign-up-wrapper .content .disclaimer {
    width: 95%;
    margin-left: 1.5em;
  }
}
.sign-up-layout .layout-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19.36px;
  margin-top: 33em;
  margin-left: 4em;
}
@media only screen and (max-width: 23.5375em) {
  .sign-up-layout .layout-icons {
    margin-left: 9px !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .layout-icons {
    width: 40%;
    margin-left: 1em;
    margin-top: -57em;
  }
}
.sign-up-layout .layout-icons .label {
  display: flex;
  width: 72.6px;
  height: 25.41px;
  align-items: center;
  gap: 12.1px;
  border-radius: 4.84px;
  background: #FFF;
  box-shadow: 0px 4.84001px 4.84001px 0px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .layout-icons .label {
    width: 57.6px;
    height: 23.41px;
  }
}
.sign-up-layout .layout-icons .label h1 {
  margin-top: 9px;
  display: flex;
  width: 72.6px;
  height: 20.57px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12.1px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.36px;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .layout-icons .label h1 {
    width: 57.6px;
    font-size: 11.1px;
  }
}
.sign-up-layout .layout-icons .ship,
.sign-up-layout .layout-icons .fedex {
  width: 60.5px;
  height: 60.5px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 4.84001px 4.84001px 0px rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .layout-icons .ship,
  .sign-up-layout .layout-icons .fedex {
    width: 50.5px;
    height: 50.5px;
  }
}
.sign-up-layout .layout-icons .ship .ups_Flat_Shield,
.sign-up-layout .layout-icons .fedex .ups_Flat_Shield {
  width: 30.501px;
  height: 36.3px;
  flex-shrink: 0;
  margin-left: 15px;
  margin-top: 14px;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .layout-icons .ship .ups_Flat_Shield,
  .sign-up-layout .layout-icons .fedex .ups_Flat_Shield {
    width: 28.501px;
    height: 33.3px;
    margin-left: 11px;
    margin-top: 10px;
  }
}
.sign-up-layout .layout-icons .ship .fedex_express,
.sign-up-layout .layout-icons .fedex .fedex_express {
  width: 48.4px;
  height: 13.417px;
  margin-left: 10px;
  margin-top: 23px;
}
@media only screen and (max-width: 32.6em) {
  .sign-up-layout .layout-icons .ship .fedex_express,
  .sign-up-layout .layout-icons .fedex .fedex_express {
    width: 44.4px;
    height: 11.417px;
    margin-left: 7px;
    margin-top: 20px;
  }
}
.dd-banner {
  width: 100%;
  min-height: 15.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background:
    linear-gradient(
      351deg,
      #DCE8E7 26%,
      #F3F3F3 55.74%);
  position: relative;
}
.dd-banner .dd-logo {
  height: auto;
  padding: 1rem 0;
}
.dd-banner .video-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  padding-top: 2rem;
}
.dd-banner .video-wrapper .filler-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  background: url(/assets/icons/Content.png) no-repeat top;
  background-size: contain;
  width: 351px;
  height: 201px;
}
.dd-banner .video-wrapper .video-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  padding-top: 1rem;
}
@media screen and (min-width: 48rem) {
  .dd-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    justify-content: space-around;
  }
}
.dd-banner .bottom-curve {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.section .section-title {
  font-size: 1.875rem;
  width: 100%;
  text-align: center;
  padding: 1em 0;
}
@media screen and (min-width: 48rem) {
  .section .section-title {
    font-size: 2.5rem;
  }
}
ul.features {
  margin: 0;
  padding: 0 3rem;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media screen and (min-width: 48rem) {
  ul.features {
    padding: 0 7rem;
  }
}
ul.features li.feature {
  color: rgba(36, 36, 36, 0.87);
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 1rem;
  line-height: 1.5;
}
ul.features li.feature::before {
  content: url(/assets/icons/checkmark_1.svg);
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 1rem;
  filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}
@media screen and (min-width: 48rem) {
  ul.features li.feature {
    width: 50%;
  }
}
ul.features li.feature sup {
  line-height: 1;
}
.subscription-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  border: solid 3px #004791;
  border-radius: 8px;
  background-color: #e0e9f2;
  font-size: 1rem;
  width: 23rem;
  padding: 1rem;
  line-height: 1;
}
.subscription-option img.radio {
  filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}
@media screen and (min-width: 48rem) {
  .subscription-option {
    width: 30rem;
    margin: 0 auto;
  }
}
.subscription-option .option-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 1rem;
  background-color: transparent;
  text-align: left;
}
.subscription-option .option-content .option-content-header {
  font-size: 1.25rem;
}
.subscription-option .option-content .option-content-sub-header {
  font-size: 0.85rem;
}
.subscription-option .renewal-date {
  margin-left: auto;
  align-self: flex-end;
  background-color: transparent;
  color: #5F5753;
}
.trademark .note-header {
  margin-bottom: 0.25em;
}
.trademark ol {
  margin: 0;
  padding-inline-start: 1em;
}
/*# sourceMappingURL=delivery-defense-sign-up.component.css.map */
