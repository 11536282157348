/* src/app/delivery-defense/dd-address-confidence-tool/recent-uploads/recent-upload-live-status-check/recent-upload-live-status-check.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.batch-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0.25em;
  text-decoration: none;
  position: relative;
  border: 2px solid black;
  min-height: 3rem;
  min-width: 25rem;
}
.batch-info:hover .value {
  transform: scale(1.2);
  transform-origin: center;
}
.batch-info .key {
  font-size: 0.7em;
  line-height: 1;
  text-align: center;
}
.batch-info .value {
  font-size: 1em;
  height: 1em;
  line-height: 1.5em;
  text-align: center;
}
.batch-info .signalrify {
  position: absolute;
  right: 2px;
  bottom: -6px;
}
:host {
  color: black;
}
:host.UPLOADED {
  background-color: #64a70b;
  color: black;
}
:host.PROCESSING {
  background-color: #ffb500;
  color: #573616;
}
:host a {
  color: inherit;
}
:host(.live-status-count) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
/*# sourceMappingURL=recent-upload-live-status-check.component.css.map */
