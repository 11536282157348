/* src/app/delivery-defense/dd-address-confidence-tool/recent-uploads/recent-upload-popup/recent-upload-popup.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.recent-uploads-table-dialog {
  font-size: 1rem;
  background-color: white;
  padding: 1rem;
}
.recent-uploads-table-dialog .dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.recent-uploads-table-dialog .dialog-header h2 {
  font-size: 24px;
  font-weight: bold;
  text-decoration: underline;
}
.recent-uploads-table-dialog .dialog-header button.close-icon {
  --icon-size: 2.5rem;
  background-color: transparent;
  border: none;
  width: var(--icon-size);
  height: var(--icon-size);
  cursor: pointer;
}
.recent-uploads-table-dialog .dialog-header button.close-icon:hover .mat-icon {
  font-weight: 700;
}
.recent-uploads-table-dialog .dialog-header button.close-icon .mat-icon {
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: var(--icon-size);
}
.recent-uploads-table-dialog .processing-records-message {
  padding-left: 1.4rem;
}
/*# sourceMappingURL=recent-upload-popup.component.css.map */
