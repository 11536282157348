/* src/app/sign-up/insurance-shipments/insurance-shipments.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.red-asterik {
  color: #d50000;
}
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  overflow: visible;
}
.insurance-shipments {
}
.insurance-shipments label {
  font-size: 16px;
  width: 100%;
  color: #5F5753;
}
.insurance-shipments label.mdc-label {
  color: #5F5753;
  font-size: 14px;
  font-style: normal;
}
.insurance-shipments span[matsuffix] {
  color: #8C857E;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .second {
    margin-left: 17px;
  }
}
.insurance-shipments .shipment-information,
.insurance-shipments .service-level-coverage,
.insurance-shipments .geographical-limits-of-coverage {
  margin: 20px 0 1em 1em;
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .shipment-information,
  .insurance-shipments .service-level-coverage,
  .insurance-shipments .geographical-limits-of-coverage {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .shipment-information .mat-mdc-form-field,
  .insurance-shipments .service-level-coverage .mat-mdc-form-field,
  .insurance-shipments .geographical-limits-of-coverage .mat-mdc-form-field {
    width: 48%;
    padding: 0em 1em;
  }
  .insurance-shipments .shipment-information .mat-mdc-form-field:nth-child(1),
  .insurance-shipments .service-level-coverage .mat-mdc-form-field:nth-child(1),
  .insurance-shipments .geographical-limits-of-coverage .mat-mdc-form-field:nth-child(1),
  .insurance-shipments .shipment-information .mat-mdc-form-field:nth-child(2),
  .insurance-shipments .service-level-coverage .mat-mdc-form-field:nth-child(2),
  .insurance-shipments .geographical-limits-of-coverage .mat-mdc-form-field:nth-child(2) {
    padding-top: 1em;
  }
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .shipment-information .mat-form-field-dropdown,
  .insurance-shipments .service-level-coverage .mat-form-field-dropdown,
  .insurance-shipments .geographical-limits-of-coverage .mat-form-field-dropdown {
    width: 52%;
    padding: 0em 1em;
  }
  .insurance-shipments .shipment-information .mat-form-field-dropdown:nth-child(1),
  .insurance-shipments .service-level-coverage .mat-form-field-dropdown:nth-child(1),
  .insurance-shipments .geographical-limits-of-coverage .mat-form-field-dropdown:nth-child(1),
  .insurance-shipments .shipment-information .mat-form-field-dropdown:nth-child(2),
  .insurance-shipments .service-level-coverage .mat-form-field-dropdown:nth-child(2),
  .insurance-shipments .geographical-limits-of-coverage .mat-form-field-dropdown:nth-child(2) {
    padding-top: 1em;
  }
}
.insurance-shipments .shipment-information .grid,
.insurance-shipments .service-level-coverage .grid,
.insurance-shipments .geographical-limits-of-coverage .grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.insurance-shipments .shipment-information .grid .value-containers,
.insurance-shipments .service-level-coverage .grid .value-containers,
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers {
  align-items: center;
}
.insurance-shipments .shipment-information .grid .value-containers .value-wrapper,
.insurance-shipments .service-level-coverage .grid .value-containers .value-wrapper,
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers .value-wrapper {
  display: flex;
  position: relative;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .shipment-information .grid .value-containers .maxmimum-value-per-package-dropdown,
  .insurance-shipments .service-level-coverage .grid .value-containers .maxmimum-value-per-package-dropdown,
  .insurance-shipments .geographical-limits-of-coverage .grid .value-containers .maxmimum-value-per-package-dropdown {
    margin-left: 35px;
  }
}
.insurance-shipments .shipment-information .grid .value-containers .total-annual-packages-insured,
.insurance-shipments .service-level-coverage .grid .value-containers .total-annual-packages-insured,
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers .total-annual-packages-insured {
  margin-right: 30px;
}
.insurance-shipments .shipment-information .grid .value-containers .header,
.insurance-shipments .service-level-coverage .grid .value-containers .header,
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers .header {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0;
  width: 100%;
  color: #5F5753;
  font-weight: 400;
  max-width: 145px;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .shipment-information .grid .value-containers .header,
  .insurance-shipments .service-level-coverage .grid .value-containers .header,
  .insurance-shipments .geographical-limits-of-coverage .grid .value-containers .header {
    width: 140px;
  }
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .shipment-information .grid .value-containers .total-annual-packages-insured-mat-form-field,
  .insurance-shipments .service-level-coverage .grid .value-containers .total-annual-packages-insured-mat-form-field,
  .insurance-shipments .geographical-limits-of-coverage .grid .value-containers .total-annual-packages-insured-mat-form-field {
    width: 52%;
  }
}
.insurance-shipments .shipment-information .grid .value-containers mat-error,
.insurance-shipments .service-level-coverage .grid .value-containers mat-error,
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers mat-error {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -0.5rem;
  left: 12rem;
  width: 100%;
  color: #d50000;
  font-size: 12px;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .shipment-information .grid .value-containers mat-error,
  .insurance-shipments .service-level-coverage .grid .value-containers mat-error,
  .insurance-shipments .geographical-limits-of-coverage .grid .value-containers mat-error {
    bottom: -1rem;
    left: 10rem;
  }
}
.insurance-shipments .shipment-information .shipping-frequency-form-field,
.insurance-shipments .service-level-coverage .shipping-frequency-form-field,
.insurance-shipments .geographical-limits-of-coverage .shipping-frequency-form-field {
  width: 60%;
}
.insurance-shipments .geographical-limits-of-coverage .grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers {
  align-items: center;
}
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers .value-wrapper {
  display: flex;
  position: relative;
}
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers .header {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0;
  width: 100%;
  min-width: 140px;
  color: #5F5753;
  font-weight: 400;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .geographical-limits-of-coverage .grid .value-containers .header {
    width: 140px;
    min-width: auto;
  }
}
.insurance-shipments .geographical-limits-of-coverage .grid .value-containers mat-error {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -0.5rem;
  left: 10rem;
  width: 100%;
  color: #d50000;
  font-size: 12px;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .geographical-limits-of-coverage .grid .value-containers mat-error {
    bottom: -1rem;
    left: 10rem;
  }
}
.insurance-shipments .geographical-limits-of-coverage .geographical-sum-error {
  margin-top: 10px;
  margin-bottom: 0;
  color: #d50000;
  font-size: 12px;
}
.insurance-shipments .service-level-coverage .grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.insurance-shipments .service-level-coverage .grid .value-containers {
  align-items: center;
}
.insurance-shipments .service-level-coverage .grid .value-containers .value-wrapper {
  display: flex;
  position: relative;
}
.insurance-shipments .service-level-coverage .grid .value-containers .header {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0;
  width: 100%;
  min-width: 140px;
  color: #5F5753;
  font-weight: 400;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .service-level-coverage .grid .value-containers .header {
    width: 140px;
    min-width: auto;
  }
}
.insurance-shipments .service-level-coverage .grid .value-containers mat-error {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -0.5rem;
  left: 10rem;
  width: 100%;
  color: #d50000;
  font-size: 12px;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .service-level-coverage .grid .value-containers mat-error {
    bottom: -1rem;
    left: 10rem;
  }
}
.insurance-shipments .service-level-coverage .service-level-sum-error {
  margin-top: 10px;
  margin-bottom: 0;
  color: #d50000;
  font-size: 12px;
}
.insurance-shipments .claim-history {
  width: 96%;
}
.insurance-shipments .claim-history .title {
  display: block;
  margin-bottom: 2em;
}
.insurance-shipments .claim-history .subtitle {
}
.insurance-shipments .claim-history .subtitle .mat-checkbox-label {
  font-weight: 600;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .claim-history .claim-detail .mat-mdc-form-field:nth-child(1) {
    flex-basis: 170px;
  }
  .insurance-shipments .claim-history .claim-detail .mat-mdc-form-field:nth-child(2) {
    flex-basis: 236px;
  }
  .insurance-shipments .claim-history .claim-detail .mat-mdc-form-field:nth-child(3) {
    flex-basis: 201px;
  }
  .insurance-shipments .claim-history .claim-detail .mat-mdc-form-field:nth-child(4) {
    flex-basis: 266px;
  }
}
.insurance-shipments .claim-history .checkbox-container {
  margin: 0.5em 0 1em 1em;
  color: #5F5753;
  font-weight: 400;
}
.insurance-shipments .business-references {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 96%;
}
.insurance-shipments .business-references .business-reference {
}
.insurance-shipments .business-references .business-reference .mat-form-field-infix {
  width: 0;
}
.insurance-shipments .business-references .business-reference .mat-mdc-form-field {
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .business-references .business-reference .mat-mdc-form-field:nth-child(1) {
    flex-basis: 314px;
  }
  .insurance-shipments .business-references .business-reference .mat-mdc-form-field:nth-child(2) {
    flex-basis: 257px;
  }
  .insurance-shipments .business-references .business-reference .mat-mdc-form-field:nth-child(3) {
    flex-basis: 155px;
  }
  .insurance-shipments .business-references .business-reference .mat-mdc-form-field:nth-child(4) {
    flex-basis: 180px;
  }
}
.insurance-shipments .business-references .business-reference .mat-mdc-form-field .mat-form-field-subscript-wrapper {
  word-break: break-all;
  position: relative;
}
.insurance-shipments .claim-detail,
.insurance-shipments .business-reference {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (min-width: 48rem) {
  .insurance-shipments .claim-detail,
  .insurance-shipments .business-reference {
    flex-direction: row;
    margin: 0em 1em;
    justify-content: space-between;
  }
}
.insurance-shipments .terms {
  border: 1px solid #cecccc;
  padding: 0em 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
}
.insurance-shipments .terms h3 {
  font-size: 20px;
  color: #4a4a4a;
  letter-spacing: 0.66px;
  font-weight: 600;
  margin-bottom: 0;
}
.insurance-shipments .terms p:first-child {
  color: #242424;
}
.insurance-shipments .terms p:last-child {
  color: #817b7b;
}
.insurance-shipments .terms p:last-child .mat-checkbox-layout .mat-checkbox-label {
  display: block;
}
.insurance-shipments .terms p {
  font-size: 14px;
}
.insurance-shipments .terms a {
  font-weight: 600;
}
.insurance-shipments .terms .signature {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.insurance-shipments .terms .signature .mat-mdc-form-field {
  flex-grow: 1;
  margin-right: 1em;
}
.insurance-shipments .terms .signature .mat-mdc-form-field:last-child {
  margin-right: 0;
}
/*# sourceMappingURL=insurance-shipments.component.css.map */
