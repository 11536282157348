/* src/app/shared/components/terms-conditions-static/terms-conditions-static.component.scss */
:host(.terms-conditions) {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
}
/*# sourceMappingURL=terms-conditions-static.component.css.map */
