/* src/app/delivery-defense/dd-score-history/dd-score-history.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.score-history-layout {
  width: 100%;
}
.score-history-layout .score-history-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 38px;
  flex-shrink: 0;
  background: #FFF;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content {
    width: 70%;
    margin: 0 auto;
    position: relative;
    padding: 0px 90px 20px 90px;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .score-history-layout .score-history-content {
    width: 70%;
    padding: 20px 50px 20px 50px;
  }
}
.score-history-layout .score-history-content .header-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3px;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: space-between;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content .header-panel {
    flex-direction: row;
  }
}
.score-history-layout .score-history-content .header-panel .header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 57px;
  flex-shrink: 0;
  color: #121212;
  font-family: Roboto;
  font-size: 32px;
  margin-bottom: 2rem;
  font-weight: 400;
  line-height: 56px;
  margin-top: 20px;
}
@media screen and (min-width: 48rem) {
  .score-history-layout .score-history-content .header-panel .header {
    font-size: 40px;
    margin-bottom: 0px;
    width: 50%;
    display: block;
    margin-top: 45px;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .score-history-layout .score-history-content .header-panel .header {
    margin-top: 18px;
  }
}
.score-history-layout .score-history-content .header-panel upsc-dd-remaining-credits {
  width: 100%;
  margin-top: 25px;
  max-width: 23.438rem;
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .score-history-layout .score-history-content .header-panel upsc-dd-remaining-credits {
    margin-top: 0px;
  }
}
.score-history-layout .score-history-content .header-panel .guage-panel {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.score-history-layout .score-history-content .header-panel .ups-frame {
  width: 50%;
}
.score-history-layout .score-history-content .page-description {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
}
.score-history-layout .score-history-content .page-description .trademark {
  font-size: 10px;
  margin-top: 5px;
}
/*# sourceMappingURL=dd-score-history.component.css.map */
