/* src/app/login/reset-password/reset-password.component.scss */
.flex-center,
.reset-password .reset-password-wrapper .page-content-items .trouble {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.reset-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.reset-password .reset-password-wrapper {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(162, 162, 162, 0.5);
  border: 1px solid #f4f4f4;
}
@media screen and (min-width: 48rem) {
  .reset-password .reset-password-wrapper {
    width: 30em;
    margin-top: 4em;
    height: auto;
  }
}
.reset-password .reset-password-wrapper .page-content-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2em;
}
@media screen and (min-width: 48rem) {
  .reset-password .reset-password-wrapper .page-content-items {
    padding: 2em 4em;
  }
}
.reset-password .reset-password-wrapper .page-content-items .logo {
  margin: 0.5em auto 2em;
}
.reset-password .reset-password-wrapper .page-content-items h2 {
  font-size: 1.125em;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.reset-password .reset-password-wrapper .page-content-items .reset-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
}
.reset-password .reset-password-wrapper .page-content-items .reset-form .valid-password {
  margin: 0 0.5em;
}
.reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message {
  width: 15.5em;
  background-color: #fff;
  border: solid 1px #f4f4f4;
  box-shadow: 0 2px 2px 0 rgba(197, 197, 197, 0.5);
  color: #707070;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -8em;
  left: 33%;
}
@media screen and (min-width: 48rem) {
  .reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message {
    top: -3em;
    left: 102%;
  }
}
.reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message .heading {
  font-weight: 600;
  margin-left: -2em;
}
.reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message ul {
  margin-top: 5px;
  margin-bottom: 5px;
}
.reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message ul li {
  margin-left: -1em;
  text-align: left;
}
.reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message::before {
  content: "";
  position: absolute;
  border: 11px solid transparent;
  top: 101%;
  right: 80%;
  border-color: #f4f4f4 transparent transparent transparent;
}
@media screen and (min-width: 48rem) {
  .reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message::before {
    top: 42%;
    right: 100%;
    border-color: transparent #f4f4f4 transparent transparent;
  }
}
.reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message::after {
  content: "";
  position: absolute;
  margin-top: 0;
  border-width: 9px;
  border-style: solid;
  top: 100%;
  right: 80%;
  border-color: #fff transparent transparent transparent;
}
@media screen and (min-width: 48rem) {
  .reset-password .reset-password-wrapper .page-content-items .reset-form [class*=mat-form-field-infix]:nth-child(1) .validation-message::after {
    margin-top: -5px;
    top: 49%;
    right: 100%;
    border-color: transparent #fff;
  }
}
.reset-password .reset-password-wrapper .page-content-items .trouble {
  text-align: center;
  width: 100%;
  font-size: 0.9em;
  color: #595555;
}
.reset-password .reset-password-wrapper .page-content-items .trouble a {
  font-weight: 600;
  color: #00857d;
}
.reset-password .reset-password-wrapper .page-content-items .success {
  display: flex;
  margin-bottom: 1em;
  flex-direction: column;
  padding: 1em;
}
.reset-password .reset-password-wrapper .page-content-items .success h2 {
  color: #242424;
  line-height: 1.5em;
  text-align: center;
  align-self: center;
  margin-bottom: 3em;
  font-size: 1.25em;
  font-weight: normal;
}
@media screen and (min-width: 48rem) {
  .reset-password .reset-password-wrapper .page-content-items .success h2 {
    width: 75%;
  }
}
/*# sourceMappingURL=reset-password.component.css.map */
