/* src/app/billing/manage-credit-card/manage-credit-card.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.manage-credit-cards .page-actions,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.manage-credit-cards {
  font-size: 1rem;
}
.manage-credit-cards .page-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1em;
  min-width: auto;
}
@media screen and (min-width: 48rem) {
  .manage-credit-cards .page-actions {
    justify-content: flex-end;
  }
}
.manage-credit-cards .page-actions .page-action {
  padding: 0 1.5rem;
}
@media screen and (min-width: 48rem) {
  .manage-credit-cards .page-actions .page-action {
    margin-left: 1em;
  }
}
.cc-logo {
  width: 50px;
}
.table > tbody > tr > td:first-child,
.table > thead > tr > td:first-child {
  padding-left: 20px;
}
input[type=radio].sky-radio {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #009cbd;
  border-radius: 50%;
  outline: none;
}
input[type=radio].sky-radio:checked:before {
  background: #009cbd;
}
input[type=radio].blue-radio:before {
  content: "";
  display: block;
  width: 58%;
  height: 58%;
  margin: 18% auto;
  border-radius: 50%;
}
/*# sourceMappingURL=manage-credit-card.component.css.map */
